import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout';
import { Container } from 'reactstrap'
import { isLoggedIn, handleProfileUpdate, handleChangePassword } from "../../services/auth";
import { navigate } from '@reach/router';
import Breadcrumb from '../../components/breadcrumb';
import { Banner } from '../../components/banner';
import { Form, FormGroup, FormFeedback, FormText, Input, Row, Col, Button, Label, Modal, ModalHeader, ModalFooter, ModalBody, } from 'reactstrap'
import { CountryDropdown } from 'react-country-region-selector'
import validator from 'validator'
import formStyles from '../../components/styles/form.module.css'
import { CODE_COGNITO, CODE_RESET } from "../../components/authcode";
import AlertComponent from '../../containers/alert';
import ReactGA from 'react-ga'

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            status: !props.location.state ? null : props.location.state.status,
            statusMessage: !props.location.state ? null : props.location.state.statusMessage,
        }
    }

    componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

        ReactGA.initialize(`$(process.env.GA_KEY)`);
        ReactGA.pageview('account/profile');
    }

    render() {
        return (
            <ProfileLayout location={this.props.location}>
                <AlertComponent status={this.state.status} statusMessage={this.state.statusMessage} style={{}} />
                <ProfileContent />
            </ProfileLayout>
        )
    }
}

export const ProfileLayout = props => {
    return (
        <Layout>
            <Banner title={`Profile`} />
            {props.location ? (<Breadcrumb loc={props.location} />) : undefined}
            <Container
                fluid={props.fluid ? true : undefined}
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}>
                {props.children}
            </Container>
        </Layout>
    )
}

export class ProfileContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            confirmPassword: "",
            oldPassword: "",
            firstName: "",
            lastName: "",
            orgName: "",
            contact: "",
            country: "",
            email: "",
            events: 0,
            api: 0,
            initialState: true,
            changedState: false,

            oldpassValid: false,
            passValid: false,
            confirmValid: false,
            firstValid: true,
            lastValid: true,
            contactValid: true,
            countryValid: false,
            orgValid: false,
            formValid: false,

            formErrors: {
                oldPassword: false,
                password: false,
                confirmPassword: false,
                firstName: false,
                lastName: false,
                contact: false,
                country: false,
                org: false

            },
            respErrors: {
                password: {
                    isValid: true,
                    message: ""
                },
                oldPassword: {
                    isValid: true,
                    message: ""
                },
                confPassword: {
                    isValid: true,
                    message: ""
                },

            },
            submitResponse: {
                isValid: true,
                message: ""
            },

            attributeValid: false,
            passwordValid: false,

            isChangePasswordLoading: false,
            isChangeAttributeLoading: false,

            changePasswordModal: false,
            changeAttributeModal: false
        }

        this.changePasswordModal = this.changePasswordModal.bind(this)
        this.changeAttributeModal = this.changeAttributeModal.bind(this)
    }

    componentDidMount() {
        this.setState({
            firstName: user.userData.attributes.given_name,
            lastName: user.userData.attributes.family_name,
            orgName: user.userData.attributes['custom:organization'],
            contact: !user.userData.attributes.phone_number ? '' : user.userData.attributes.phone_number,
            country: user.userData.attributes['custom:country'].split('-').slice(-1).join('-'),
            email: user.userData.attributes.email,
            events: user.userData.attributes['custom:newsletter'],
            api: user.userData.attributes['custom:api-update'],
        })
    }

    // Change Password
    changePasswordModal() {
        this.setState({
            changePasswordModal: !this.state.changePasswordModal
        })
    }

    // Change Attribute
    changeAttributeModal() {
        this.setState({
            changeAttributeModal: !this.state.changeAttributeModal
        })
    }

    selectCountry = val => {
        const name = 'country';
        const value = val;
        this.setState({ [name]: value },
            () => {
                this.validateField(name, value)
            }
        );
    }

    handleCheckbox = event => {

        let name = event.target.id
        let val = event.target.checked ? 1 : 0

        this.setState({
            [name]: val,
            changedState: true,
            attributeValid: true
        });
    }

    validateField(field, value) {
        let password = this.state.password
        let oldpassValid = this.state.oldpassValid
        let oldPassword = this.state.oldPassword
        let passValid = this.state.passValid
        let confirmPassword = this.state.confirmPassword
        let confirmValid = this.state.confirmValid
        let firstValid = this.state.firstValid
        let lastValid = this.state.lastValid
        let contactValid = this.state.contactValid
        let countryValid = this.state.countryValid
        let orgValid = this.state.orgValid
        let changedState = this.state.changedState
        let initialState = this.state.initialState

        let fieldValidationMessages = this.state.respErrors
        let fieldValidationErrors = this.state.formErrors

        switch (field) {
            case 'oldPassword':
                fieldValidationMessages.oldPassword.message = oldpassValid ? '' : undefined;
                initialState = false
                changedState = false

                if (validator.equals(value, password)) {
                    passValid = false
                    fieldValidationErrors.password = true
                    fieldValidationMessages.password.message = "Your new password must not be the same as your old password"
                } else {
                    fieldValidationMessages.password.message = ""
                }

                if (!validator.matches(value, RegExp('^(?=.*[A-Z])'))) {
                    fieldValidationMessages.oldPassword.message = "At least 1 upper case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[a-z])'))) {
                    fieldValidationMessages.oldPassword.message = "At least 1 lower case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[0-9])'))) {
                    fieldValidationMessages.oldPassword.message = "At least 1 number is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[!@#$%^&*])'))) {
                    fieldValidationMessages.oldPassword.message = "At least 1 symbol is required";
                }

                if (!validator.matches(value, RegExp('^(?=.{14,})'))) {
                    fieldValidationMessages.oldPassword.message = "Password must have at least 14 characters";
                }

                if (validator.isEmpty(value)) {
                    fieldValidationMessages.oldPassword.message = "Password must not be empty";
                }



                oldpassValid = (validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && !validator.isEmpty(value) && (!validator.equals(value, password) || !validator.equals(value, oldPassword)))
                fieldValidationErrors.oldPassword = oldpassValid ? false : true;
                break;

            case 'password':
                initialState = false
                changedState = false


                fieldValidationMessages.password.message = passValid ? '' : undefined;

                if (confirmValid) {
                    if (!validator.equals(value, confirmPassword)) {
                        fieldValidationMessages.password.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.password.message = ""
                    }
                } else {
                    if (!validator.equals(value, confirmPassword)) {
                        fieldValidationMessages.password.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.password.message = ""
                    }
                }

                if (oldpassValid) {
                    if (validator.equals(value, oldPassword)) {
                        fieldValidationMessages.password.message = "Your new password must not be the same as your old password"
                    } else {
                        fieldValidationMessages.password.message = ""
                    }
                }

                if (!validator.matches(value, RegExp('^(?=.*[A-Z])'))) {
                    fieldValidationMessages.password.message = "At least 1 upper case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[a-z])'))) {
                    fieldValidationMessages.password.message = "At least 1 lower case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[0-9])'))) {
                    fieldValidationMessages.password.message = "At least 1 number is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[!@#$%^&*])'))) {
                    fieldValidationMessages.password.message = "At least 1 symbol is required";
                }

                if (!validator.matches(value, RegExp('^(?=.{14,})'))) {
                    fieldValidationMessages.password.message = "Password must have at least 14 characters";
                }

                if (validator.isEmpty(value)) {
                    fieldValidationMessages.password.message = "Password must not be empty";
                }



                passValid = (validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && validator.matches(confirmPassword, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && !validator.isEmpty(value) && !validator.isEmpty(confirmPassword) && !validator.equals(value, oldPassword) && validator.equals(value, confirmPassword))
                fieldValidationErrors.password = passValid ? false : true;
                break;

            case 'confirmPassword':
                initialState = false
                changedState = false
                confirmValid = validator.equals(value, password) && !validator.isEmpty(password) && validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && !validator.isEmpty(value) && !validator.equals(value, oldPassword);
                fieldValidationErrors.confirmPassword = confirmValid ? false : true;
                fieldValidationErrors.password = confirmValid ? false : true;
                fieldValidationMessages.password.message = confirmValid ? "" : "The passwords you entered do not match"
                fieldValidationMessages.confPassword.message = !validator.equals(value, oldPassword) ? "" : "Your new password must not be the same as your old password"
                fieldValidationMessages.password.message = !validator.equals(value, oldPassword) ? "" : "Your new password must not be the same as your old password"

                if (passValid) {
                    if (!validator.equals(value, password)) {
                        fieldValidationMessages.confPassword.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.confPassword.message = ""
                    }
                } else {
                    if (!validator.equals(value, password)) {
                        fieldValidationMessages.confPassword.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.confPassword.message = ""
                    }
                }

                passValid = confirmValid
                break;

            case 'firstName':
                firstValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        firstNameMessage: "Please fill up your First name"
                    })
                } else {
                    this.setState({
                        firstNameMessage: "Invalid characters used"
                    })
                }
                fieldValidationErrors.firstName = firstValid ? false : true;
                changedState = true
                break;

            case 'lastName':
                lastValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        lastNameMessage: "Please fill up your Last name"
                    })
                } else {
                    this.setState({
                        lastNameMessage: "Invalid characters used"
                    })
                }
                fieldValidationErrors.lastName = lastValid ? false : true;
                changedState = true

                break;

            case 'contact':
                contactValid = (validator.isNumeric(value, { no_symbols: false }) && (validator.matches(value, RegExp('^[+]{1}'))));
                fieldValidationErrors.contact = contactValid ? false : true;
                changedState = true

                break;

            case 'orgName':
                orgValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        orgNameMessage: "Please indicate your organization name"
                    })
                } else {
                    this.setState({
                        orgNameMessage: "Invalid characters used"
                    })
                }
                fieldValidationErrors.org = orgValid ? false : true;
                changedState = true

                break;

            case 'country':
                countryValid = validator.isISO31661Alpha2(value);
                fieldValidationErrors.country = countryValid ? false : true;
                changedState = true

                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            oldpassValid: oldpassValid,
            passValid: passValid,
            confirmValid: confirmValid,
            firstValid: firstValid,
            lastValid: lastValid,
            contactValid: contactValid,
            countryValid: countryValid,
            changedState: changedState,
            initialState: initialState
        }, () => {
            this.validateForm()
        })
    }

    validateForm() {
        this.setState({
            attributeValid:
                this.state.contactValid && this.state.firstValid && this.state.lastValid && this.state.contactValid,
            passwordValid:
                this.state.confirmValid && this.state.passValid && this.state.oldpassValid,
        })
    }

    isASCII(str) {
        return RegExp('^[\x00-\x7F]*$').test(str);
    }

    // Handle value change for the input and save it into the state
    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        },
            () => {
                this.validateField(name, value)
            }
        );
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        console.log('-- Profile Update --');

        await handleProfileUpdate(this.state)
            .then(async (res) => {
                if (res.isValid) {
                    this.setState({
                        submitResponse: {
                            isValid: true,
                            message: "User details has been updated successfully",
                            isChangeAttributeLoading: false
                        }
                    }, () => {
                        this.changeAttributeModal()
                    })
                } else {
                    console.log('Profile Update Response Error Handler : ' + res.message);

                    switch (res.code) {
                        case CODE_COGNITO.NETWORK_ERROR:
                            res.message = "Network Error. Please check your internet connection."
                            break;

                        case CODE_RESET.RESET_INVALID:
                            res.message = "The New Password you have entered is incorrect."
                            break;

                        default:
                            break;
                    }
                    this.setState({
                        submitResponse: {
                            isValid: false,
                            message: res.message
                        }
                    }, this.validateForm);
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    submitResponse: {
                        isValid: false,
                        message: err.message
                    }
                })
            })
    }

    handleChangePassword = async (event) => {
        event.preventDefault()

        this.setState({
            isChangePasswordLoading: true
        })
        await handleChangePassword(this.state)
            .then(async (res) => {
                if (res.isValid) {
                    this.setState({
                        submitResponse: {
                            isValid: true,
                            isChangePasswordLoading: false
                        }
                    }, () => {
                        this.changePasswordModal()
                    })
                } else {
                    console.log('Change Password Response Error Handler : ' + res.message);

                    switch (res.code) {
                        case CODE_COGNITO.NETWORK_ERROR:
                            res.message = "Network Error. Please check your internet connection."
                            this.setState({
                                isChangePasswordLoading: false,
                                respErrors: {
                                    password: {
                                        isValid: true,
                                        message: ""
                                    },
                                    oldPassword: {
                                        isValid: false,
                                        message: res.message
                                    },
                                    confPassword: {
                                        isValid: false,
                                        message: ""
                                    }
                                }
                            }, () => {
                                this.validateForm()
                            })
                            break;

                        case CODE_RESET.RESET_INVALID:
                            res.message = "The password you have entered is incorrect."
                            this.setState({
                                isChangePasswordLoading: false,
                                oldpassValid: false,
                                formErrors: {
                                    oldPassword: true,
                                    password: false,
                                    confirmPassword: false,
                                    firstName: false,
                                    lastName: false,
                                    contact: false,
                                    country: false,
                                    org: false
                                },
                                respErrors: {
                                    password: {
                                        isValid: true,
                                        message: ""
                                    },
                                    oldPassword: {
                                        isValid: false,
                                        message: res.message
                                    },
                                    confPassword: {
                                        isValid: false,
                                        message: ""
                                    }
                                }
                            }, () => {
                                this.validateForm()
                            })
                            break;

                        case CODE_RESET.RESET_INCORRECT:
                            res.message = "The password you have entered is incorrect."
                            this.setState({
                                isChangePasswordLoading: false,
                                oldpassValid: false,
                                formErrors: {
                                    oldPassword: true,
                                    password: false,
                                    confirmPassword: false,
                                    firstName: false,
                                    lastName: false,
                                    contact: false,
                                    country: false,
                                    org: false
                                },
                                respErrors: {
                                    password: {
                                        isValid: true,
                                        message: ""
                                    },
                                    oldPassword: {
                                        isValid: false,
                                        message: res.message
                                    },
                                    confPassword: {
                                        isValid: false,
                                        message: ""
                                    }
                                }
                            }, () => {
                                this.validateForm()
                            })
                            break

                        default:
                            this.setState({
                                isChangePasswordLoading: false,
                                oldpassValid: false,
                                formErrors: {
                                    oldPassword: true,
                                    password: false,
                                    confirmPassword: false,
                                    firstName: false,
                                    lastName: false,
                                    contact: false,
                                    country: false,
                                    org: false
                                },
                                respErrors: {
                                    password: {
                                        isValid: true,
                                        message: ""
                                    },
                                    oldPassword: {
                                        isValid: false,
                                        message: res.message
                                    },
                                    confPassword: {
                                        isValid: false,
                                        message: ""
                                    }
                                }
                            }, () => {
                                this.validateForm()
                            })
                            break;
                    }

                }
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    submitResponse: {
                        isValid: false,
                        message: err.message
                    }
                })
            })
    }

    render() {
        const { country } = this.state;

        return (

            <Row >
                <Col md={{ size: 8, offset: 2 }} className={formStyles.signupContainer}>
                    <Col sm="12" md="12" >
                        <Form onSubmit={this.handleChangePassword} className="clearfix">
                            <FormText className="text-center">
                                <i>All fields are mandatory unless otherwise stated</i><br />
                                <i>Please note details updated on this portal will not be updated on your NETS merchant profile.</i>
                            </FormText>
                            <h1 style={{
                                fontWeight: 'bolder',
                                textAlign: "center"
                            }}>
                                My Profile
                            </h1>
                            {/* CHANGE PASSWORD */}
                            <FormGroup>
                                <Label for="password">
                                    Old Password
                                </Label>

                                <Input
                                    id="oldPassword"
                                    type="password"
                                    name="oldPassword"
                                    autoComplete="off"
                                    value={this.state.oldPassword.value}
                                    onChange={this.handleChange}
                                    className={formStyles.signUp}
                                    autoFocus
                                    required
                                    onDrop={(event) => { event.preventDefault() }}
                                    onCopy={(event) => { event.preventDefault() }}
                                    onPaste={(event) => { event.preventDefault() }}
                                    invalid={this.state.formErrors.oldPassword && !this.state.initialState}
                                    valid={this.state.oldpassValid && !this.state.initialState}
                                />
                                <FormFeedback invalid={!this.state.oldpassValid ? '' : undefined}>
                                    {this.state.respErrors.oldPassword.message}

                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">New Password </Label>
                                <Input
                                    id="password"
                                    type="password"
                                    name="password"
                                    autoComplete="off"
                                    onDrop={(event) => { event.preventDefault() }}
                                    onCopy={(event) => { event.preventDefault() }}
                                    onPaste={(event) => { event.preventDefault() }}
                                    value={this.state.password.value}
                                    onChange={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.passValid && !this.state.initialState}
                                    invalid={this.state.formErrors.password && !this.state.initialState ? true : undefined}
                                />
                                <FormFeedback invalid={this.state.respErrors.password.isValid ? '' : undefined}>
                                    {this.state.respErrors.password.message}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="confirmPassword">Confirm New Password</Label>
                                <Input
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    autoComplete="off"
                                    onDrop={(event) => { event.preventDefault() }}
                                    onCopy={(event) => { event.preventDefault() }}
                                    onPaste={(event) => { event.preventDefault() }}
                                    value={this.state.confirmPassword.value}
                                    onChange={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.confirmValid && !this.state.initialState}
                                    invalid={this.state.formErrors.confirmPassword && !this.state.initialState ? true : undefined}
                                />
                                <FormFeedback invalid={this.state.formErrors.confirmPassword ? '' : undefined}>
                                    {this.state.respErrors.confPassword.message}
                                </FormFeedback>
                            </FormGroup>
                            {/* Desktop Display */}
                            <div className="float-right d-none d-md-block">
                                <Button color="link" className={formStyles.buttonStyles}
                                    onClick={
                                        event => {
                                            event.preventDefault();
                                            navigate('/');
                                        }} >Cancel</Button>

                                <Button
                                    color={!this.state.passwordValid || this.state.isChangePasswordLoading ? 'secondary' : 'primary'}
                                    className={formStyles.buttonStyles}
                                    disabled={!this.state.passwordValid || this.state.isChangePasswordLoading}
                                >
                                    {this.state.isChangePasswordLoading ? 'Loading...' : 'Change Password'}
                                </Button>
                            </div>
                            <br className="d-md-none" />
                            {/* Mobile Display */}
                            <Button
                                color={!this.state.formValid || this.state.isChangePasswordLoading ? 'secondary' : 'primary'}
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')}
                                block
                                disabled={!this.state.passwordValid || this.state.isChangePasswordLoading}
                            >
                                {this.state.isChangePasswordLoading ? 'Loading...' : 'Change Password'}
                            </Button>
                            <Button color="link"
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')} block
                                onClick={
                                    event => {
                                        event.preventDefault();
                                        navigate('/');
                                    }}
                            >
                                Cancel
                            </Button>

                            <Modal isOpen={this.state.changePasswordModal} toggle={this.changePasswordModal} className={this.props.className}>
                                <ModalHeader toggle={this.changePasswordModal}>Success!</ModalHeader>
                                <ModalBody>
                                    Password has been updated!
                                </ModalBody>
                                <ModalFooter>.
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            this.changePasswordModal()
                                            window.location.replace('/', { state: { data: "success" } })
                                        }}
                                    >
                                        Continue
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </Form>
                        <hr />
                        {/* CHANGE ATTRIBUTES */}
                        <Form onSubmit={this.handleSubmit}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            required
                                            defaultValue={!user ? '' : user.userData.attributes.given_name}
                                            value={this.state.firstName.value}
                                            onChange={this.handleChange}
                                            className={formStyles.signUp}
                                            valid={this.state.firstValid}
                                            invalid={this.state.formErrors.firstName ? true : undefined}
                                        />
                                        <FormFeedback invalid={this.state.formErrors.firstName ? '' : undefined}>
                                            {this.state.firstNameMessage}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            required
                                            defaultValue={!user ? '' : user.userData.attributes.family_name}
                                            value={this.state.lastName.value}
                                            onChange={this.handleChange}
                                            className={formStyles.signUp}
                                            valid={this.state.lastValid}
                                            invalid={this.state.formErrors.lastName ? true : undefined}
                                        />
                                        <FormFeedback invalid={this.state.formErrors.lastName ? '' : undefined}>
                                            {this.state.lastNameMessage}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    name="email"
                                    required
                                    readOnly
                                    value={this.state.email}
                                />

                            </FormGroup>

                            <FormGroup>
                                <Label for="contact">Contact Number</Label>
                                <Input
                                    id="contact"
                                    type="text"
                                    name="contact"
                                    required
                                    defaultValue={!user ? '' : user.userData.attributes.phone_number}
                                    value={this.state.contact.value}
                                    onChange={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.contactValid}
                                    invalid={this.state.formErrors.contact ? true : undefined}
                                />
                                <FormFeedback
                                    invalid={this.state.formErrors.contact ? '' : undefined}
                                >
                                    Please provide a valid contact number.<br />
                                    Must include the country code (e.g. +65xxxxxxxx )
                            </FormFeedback>
                            </FormGroup>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="orgName">Company Name</Label>
                                        <Input
                                            id="orgName"
                                            type="text"
                                            name="orgName"
                                            defaultValue={!user ? '' : user.userData.attributes['custom:organization']}
                                            value={this.state.lastName.value}
                                            onChange={this.handleChange}
                                            className={formStyles.signUp}
                                            invalid={this.state.formErrors.org ? true : undefined}
                                        />
                                        <FormFeedback
                                            invalid={this.state.formErrors.org ? '' : undefined}
                                        >
                                            {this.state.orgNameMessage}
                                        </FormFeedback>

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="country" className="clearfix">Country</Label>
                                        <br className="d-md-block" />
                                        <CountryDropdown
                                            id="country"
                                            name="country"
                                            value={country}
                                            showDefaultOption={false}
                                            onChange={this.selectCountry}
                                            valueType="short"
                                            priorityOptions={['SG']}
                                            className={[formStyles.dropdownStyle, "form-control"].join(' ')}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup check>
                                <Label check>
                                    <Input defaultChecked={!user ? false : user.userData.attributes['custom:newsletter'] === `1` ? true : false} type="checkbox" id="events" value={1} onChange={this.handleCheckbox} onClick={this.handleCheckbox} />{' '}
                                    Sign me up for Events Newsletter
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input defaultChecked={!user ? false : user.userData.attributes['custom:api-update'] === `1` ? true : false} type="checkbox" id="api" value={1} onChange={this.handleCheckbox} onClick={this.handleCheckbox} />{' '}
                                    Sign me up for API Updates
                            </Label>
                            </FormGroup>

                            <FormGroup>
                                <Input type="hidden"
                                    valid={this.state.submitResponse.isValid ? true : undefined}
                                    invalid={!this.state.submitResponse.isValid ? true : undefined}
                                />
                                <FormFeedback
                                    invalid={!this.state.submitResponse.isValid ? '' : undefined}
                                >
                                    {this.state.submitResponse.message}
                                </FormFeedback>
                            </FormGroup>
                            {/* Desktop Display */}
                            <div className="float-right d-none d-md-block">
                                <Button color="link" className={formStyles.buttonStyles}
                                    onClick={
                                        event => {
                                            event.preventDefault();
                                            navigate('/');
                                        }} >
                                    Cancel
                            </Button>

                                <Button
                                    color={!this.state.attributeValid || !this.state.changedState ? 'secondary' : 'primary'}
                                    className={formStyles.buttonStyles}
                                    disabled={!this.state.attributeValid || !this.state.changedState}
                                >
                                    Update Profile
                            </Button>
                            </div>

                            <br className="d-md-none" />

                            {/* Mobile Display */}
                            <Button
                                color={!this.state.attributeValid || !this.state.changedState ? 'secondary' : 'primary'}
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')}
                                block
                                disabled={!this.state.attributeValid || !this.state.changedState}
                            >
                                Update Profile
                            </Button>
                            <Button color="link"
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')} block
                                onClick={
                                    event => {
                                        event.preventDefault();
                                        navigate('/');
                                    }}
                            >
                                Cancel
                            </Button>
                            <Modal isOpen={this.state.changeAttributeModal} toggle={this.changeAttributeModal} className={this.props.className}>
                                <ModalHeader toggle={this.changeAttributeModal}>Success!</ModalHeader>
                                <ModalBody>
                                    User profile has been updated!
                                </ModalBody>
                                <ModalFooter>.
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            this.changeAttributeModal()
                                            window.location.replace('/')
                                        }}
                                    >
                                        Continue
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </Form>
                    </Col>
                </Col>
            </Row>
        );
    }
}